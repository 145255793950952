import { useImagesOptimizedMapped } from '@shared/hooks/useImagesOptimizedMapped';
import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';

export function useReportOptimizedImages() {
  const imagesData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "assets" }
          extension: { in: ["png", "jpg"] }
          relativeDirectory: { eq: "images/reports" }
        }
      ) {
        nodes {
          name
          image: childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  `);

  return useImagesOptimizedMapped(imagesData) as { name: string; content: ImageDataLike }[];
}
