import React from 'react';
import { useSearchParam } from 'react-use';
import { useTranslation } from 'react-i18next';

import { ReportImage } from '@widgets/reports/report-image';

import { useIsSpecificLanguage } from '@shared/hooks/useIsSpecificLanguage';
import { Banner } from '@shared/ui/banner';

import { useNavigate } from '@hooks/useNavigate';

const MonthlyReportPage = () => {
  const isUkLanguage = useIsSpecificLanguage('uk');

  const { t } = useTranslation();
  const navigate = useNavigate();

  /**
   * If backend inject `e` param in the query string it means that user doesn't have
   * email and we need to show a notification for him
   */
  const isUserHasNoEmail = useSearchParam('e') !== null;

  return (
    <div>
      {isUserHasNoEmail && (
        <Banner className="mb-4" action={t('go-to-profile')} onActionClick={() => navigate('/profile')}>
          {t('report-need-email')}
        </Banner>
      )}
      <ReportImage name="11.2022" type={isUkLanguage ? 'ukraine' : 'foreign'} alt="Monthly report for volunteers" />
    </div>
  );
};

export default MonthlyReportPage;
