import React from 'react';
import clsx from 'clsx';

import { Button } from '../buttons/Button';
import { Element } from '@shared/interfaces/element.interface';

interface BannerProps extends Element {
  children: React.ReactNode | string;

  action?: string;

  onActionClick?: () => void;
}

export const Banner: React.FC<BannerProps> = ({ children, onActionClick, action = '', className }) => {
  return (
    <div
      className={clsx(
        'w-full py-5 border-y border-y-1 border-y-black-7 bg-beige text-xs md:flex md:items-center md:justify-between px-4 lg:px-gutters',
        className,
      )}
    >
      <div>{children}</div>

      {action && (
        <div className="flex justify-end mt-5 md:mt-0">
          <Button
            as="button"
            variant="rich-blue"
            size="small"
            className="font-medium whitespace-nowrap"
            onClick={onActionClick}
          >
            {action}
          </Button>
        </div>
      )}
    </div>
  );
};
