import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { useReportOptimizedImages } from './hooks/useReportOptimizedImages';
import useMaxWidthMediaQuery from '@hooks/useMaxWidthMediaQuery';
import clsx from 'clsx';

interface ReportImageProps {
  name: string;

  type: 'foreign' | 'ukraine';

  alt: string;
}

export const ReportImage = ({ name, type, alt }: ReportImageProps) => {
  const images = useReportOptimizedImages();
  const isMobileScreen = useMaxWidthMediaQuery('md');

  const imageName = `${name}-${type}-${isMobileScreen ? 'mobile' : 'desktop'}`;
  const image = images.find((image) => image.name === imageName);

  if (!image) {
    return null;
  }

  return (
    <div className="flex justify-center select-none pointer-events-none">
      <GatsbyImage
        image={getImage(image.content)!}
        alt={alt}
        className={clsx('w-full object-cover', !isMobileScreen && 'max-w-[1123px]')}
      />
    </div>
  );
};
